import FlagQuiz from "./FlagQuiz";

function App() {
  return (
    <div className="App">
      <FlagQuiz />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
//import JSConfetti from 'js-confetti';

const FLAG_CDN_URL = "https://flagcdn.com/w320";

const countries = [
  { "code": "us", "name": "United States" },
  { "code": "fr", "name": "France" },
  { "code": "jp", "name": "Japan" },
  { "code": "de", "name": "Germany" },
  { "code": "gb", "name": "United Kingdom" },
  { "code": "it", "name": "Italy" },
  { "code": "es", "name": "Spain" },
  { "code": "cn", "name": "China" },
  { "code": "ru", "name": "Russia" },
  { "code": "in", "name": "India" }
];

const getRandomCountries = (correctCountry) => {
  let options = [correctCountry];
  while (options.length < 3) {
    const randomCountry = countries[Math.floor(Math.random() * countries.length)];
    if (!options.some(option => option.code === randomCountry.code)) {
      options.push(randomCountry);
    }
  }
  return options.sort(() => Math.random() - 0.5);
};

export default function FlagQuiz() {
  const [remainingCountries, setRemainingCountries] = useState([...countries]);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [options, setOptions] = useState([]);
  const [score, setScore] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [isTextInput, setIsTextInput] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [wrongSelection, setWrongSelection] = useState(null);

  useEffect(() => {
    if (remainingCountries.length > 0) {
      const nextCountry = remainingCountries[Math.floor(Math.random() * remainingCountries.length)];
      setCurrentCountry(nextCountry);
      setOptions(getRandomCountries(nextCountry));
    }
  }, [remainingCountries]);

//  useEffect(() => {
//    if (remainingCountries.length === 0 && (score / attempts) >= 0.7) {
//      const jsConfetti = new JSConfetti();
//      jsConfetti.addConfetti();
//    }
//  }, [remainingCountries]);

  const handleGuess = (guess) => {
    setAttempts(attempts + 1);
    if (guess.name === currentCountry.name) {
      setScore(score + 1);
      setFeedback("Correct!");
      setWrongSelection(null);
    } else {
      setFeedback(`Wrong! The correct answer was ${currentCountry.name}`);
      setWrongSelection(guess.name);
    }
    setTimeout(() => {
      setRemainingCountries(remainingCountries.filter((c) => c.code !== currentCountry.code));
      setFeedback(null);
      setUserInput("");
      setWrongSelection(null);
    }, 1000);
  };

  const handleSubmit = () => {
    const matchedCountry = countries.find(country => country.name.toLowerCase() === userInput.toLowerCase());
    if (matchedCountry) {
      handleGuess(matchedCountry);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  if (remainingCountries.length === 0) {
    return (
      <div style={styles.quizContainer}>
        <h1 style={styles.title}>Quiz Complete!</h1>
        <p>Your score: {score} / {attempts}</p>
        <p>Accuracy: {((score / attempts) * 100).toFixed(2)}%</p>
        <button style={styles.playAgain} onClick={() => window.location.reload()}>Play Again</button>
      </div>
    );
  }

  return (
    <div style={styles.quizContainer}>
      <h1 style={styles.title}>Guess the Flag</h1>
      <div style={styles.flagContainer}>
        {currentCountry && (
          <img src={`${FLAG_CDN_URL}/${currentCountry.code.toLowerCase()}.png`} alt="Country flag" style={styles.flagImage} />
        )}
      </div>
      
      {isTextInput ? (
        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "20px" }}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            style={styles.inputField}
            placeholder="Type country name..."
          />
          <button onClick={handleSubmit} style={styles.submitButton}>Guess</button>
        </div>
      ) : (
        <div style={styles.options}>
          {options.map((option) => (
            <button
              key={option.code}
              style={{ 
                ...styles.optionButton, 
                backgroundColor: feedback && option.name === currentCountry.name ? "#28a745" : 
                  feedback && option.name === wrongSelection ? "#e74c3c" : "#444"
              }}
              onClick={() => handleGuess(option)}
            >
              {option.name}
            </button>
          ))}
        </div>
      )}
      <div style={styles.toggleContainer}>
        <label style={styles.toggleLabel}>Multiple Choice</label>
        <div style={styles.toggleSwitch} onClick={() => setIsTextInput(!isTextInput)}>
          <div style={{ ...styles.toggleKnob, transform: isTextInput ? "translateX(24px)" : "translateX(0px)" }}></div>
        </div>
        <label style={styles.toggleLabel}>Text Input</label>
      </div>
      {feedback && <p style={styles.feedback}>{feedback}</p>}
      <div style={styles.scoreContainer}>
        <span style={{ ...styles.lozenge, backgroundColor: "#28a745" }}>Correct: {score}</span>
        <span style={{ ...styles.lozenge, backgroundColor: "#e74c3c" }}>Incorrect: {attempts - score}</span>
      </div>
      <p>{attempts} of {countries.length} guessed</p>
    </div>
  );
  }

const styles = {
  toggleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
  },
  toggleSwitch: {
    width: "50px",
    height: "24px",
    backgroundColor: "#ddd",
    borderRadius: "12px",
    position: "relative",
    cursor: "pointer",
    margin: "0 10px",
  },
  toggleKnob: {
    width: "20px",
    height: "20px",
    backgroundColor: "white",
    borderRadius: "50%",
    position: "absolute",
    top: "2px",
    left: "2px",
    transition: "transform 0.3s",
  },
  toggleLabel: {
    fontSize: "1em",
    color: "#333",
  },
  inputField: {
    width: "100%",
    maxWidth: "300px",
    padding: "10px",
    fontSize: "1.1em",
    border: "1px solid #ccc",
    borderRadius: "5px",
    textAlign: "center",
 //   marginBottom: "20px",
  },
  quizContainer: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: "350px",
    width: "100%",
    margin: "auto",
  },
  title: {
    fontSize: "2em",
    marginBottom: "20px",
  },
  flagContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  flagImage: {
    width: "100%",
    maxWidth: "220px",
    display: "block",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
  },
  options: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  optionButton: {
    backgroundColor: "#444",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "1.1em",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s",
    width: "100%",
    maxWidth: "240px",
  },
  feedback: {
    fontSize: "1.2em",
    color: "#333",
    marginBottom: "20px",
    fontWeight: "600",
  },
  scoreContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginBottom: "20px",
  },
  lozenge: {
    padding: "5px 15px",
    borderRadius: "20px",
    fontSize: "1.1em",
    color: "white",
  },
  playAgain: {
    padding: "10px 20px",
    fontSize: "1.2em",
    color: "white",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },

submitButton: {
      backgroundColor: "#444",
    color: "white",
    border: "none",
    padding: "10px 20px",
    fontSize: "1.1em",
    cursor: "pointer",
    borderRadius: "5px",
    transition: "background-color 0.3s",
},
};
